<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col>
        <v-card>
          <v-card-title>
            Gebruiker {{ user.firstName }} {{ user.lastName }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <p>
                  {{ user.email }}<br>

                  {{ user.type }} van
                  <router-link :to="{ name: 'organizationsShow', params: { id: organization._id }}">
                    {{ organization.title }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        type: '',
      },
      organization: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Gebruikers',
          disabled: false,
          exact: true,
          to: { name: 'users' },
        },
        {
          text: `Gebruiker ${this.user.firstName} ${this.user.lastName}`,
          disabled: true,
          exact: true,
          to: { name: 'users' },
        },
      ];
    },
  },
  beforeMount() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      const url = `${config.VUE_APP_API_BASE_URL}/users/${this.$route.params.id}`;

      this.loading = true;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.user = response.data.user;

          if (response.data.organization) {
            this.organization = response.data.organization;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
